import React from 'react';
import {graphql, Link} from 'gatsby';
import {TextField, Select, InputAdornment, FormHelperText} from '@material-ui/core';
import {css} from '@emotion/core';
import ToolBarWithoutDrawer from '../components/toolbar-without-drawer';
import {
	Container,
	ActionBar,
	mainTextCSS,
	subTextCSS,
	BankInfo,
	bankDescriptionCSS,
	backButtonCSS,
	bankLogoCSS,
	formCSS,
	returnsTableCSS,
	bankLogoContainer,
	Button,
	Selectors,
	Label,
} from '../styles/productDetails-styles';

import BackButton from '../assets/product/back-button.svg';

const ProductDetailsTemplate = ({data}, props) => {
	const bank = data.deposits.bank;
	const inititalState = {
		income: 1000,
		deposit: 1000,
		numberOfYears: bank.number_of_years[0],
		interest: bank.rate_of_interest[0],
		bankCode: bank.bank_code,
		error: null,
	};
	const [regularDepositCredentials, setRegularDepositCredentials] = React.useState({
		...inititalState,
	});
	const [showExpectedReturns, setShowExpectedReturns] = React.useState(false);
	const {income, deposit, numberOfYears, interest, error} = regularDepositCredentials;
	const isInvalid = income === '' || deposit === '';
	const handleChange = (event) => {
		if (event.target.name === 'numberOfYears') {
			setRegularDepositCredentials({
				...regularDepositCredentials,
				[event.target.name]: event.target.value,
				interest: event.target.children[event.target.selectedIndex].getAttribute('data'),
			});
		} else if (event.target.name === 'interest') {
			setRegularDepositCredentials({
				...regularDepositCredentials,
				[event.target.name]: event.target.value,
				numberOfYears: event.target.children[event.target.selectedIndex].getAttribute(
					'data',
				),
			});
		} else
			setRegularDepositCredentials({
				...regularDepositCredentials,
				[event.target.name]: event.target.value,
			});
	};

	const onSubmitForm = (event) => {
		// props.firebase
		// 	.doSignInWithEmailAndPassword(regularDepositCredentials.email, regularDepositCredentials.password)
		// 	.then(() => {
		// 		setRegularDepositCredentials({...inititalState});
		// 		navigate(ROUTES.HOME);
		// 	})
		// 	.catch((Error) => {
		// 		setRegularDepositCredentials({
		// 			...regularDepositCredentials,
		// 			error: Error,
		// 		});
		// 	});

		event.preventDefault();
	};

	const ReturnsTable = () => {
		const shortNamedMonths = [
			'Jan',
			'Feb',
			'Mar',
			'Apr',
			'May',
			'Jun',
			'Jul',
			'Aug',
			'Sep',
			'Oct',
			'Nov',
			'Dec',
		];
		const date = new Date();
		const currentMonthIndex = date.getMonth();
		const currentDate = date.getDate();
		let currentYear = date.getFullYear();

		const monthlyPayoutAmount = Math.round(deposit * ((1 + interest / 400) ** (1 / 3) - 1));

		const firstMaturityMonthIndex = currentMonthIndex + 1;
		let expetedReturns = [];

		for (
			let i = firstMaturityMonthIndex;
			i < firstMaturityMonthIndex + numberOfYears * 12;
			i += 1
		) {
			expetedReturns.push(
				`${currentDate} ${shortNamedMonths[i % 12]} ${
					i % 12 === 0 ? currentYear+=1 : currentYear
				}`,
			);
		}

		return (
			deposit && (
				<div css={returnsTableCSS}>
					<table>
						<thead>
							<tr>
								<th>Date</th>
								<th>Description</th>
								<th>Amount</th>
							</tr>
						</thead>
						<tbody>
							{expetedReturns.map((value, index) => (
								<tr>
									<td>{value}</td>
									<td>Installment {index + 1}</td>
									<td>{monthlyPayoutAmount}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			)
		);
	};
	return (
		<Container>
			<ActionBar>
				<Link to="/deposits/">
					<img css={backButtonCSS} src={BackButton} alt="Back" />
				</Link>
				<p css={mainTextCSS}>Regular fixed deposit</p>
				<p css={subTextCSS}>
					Provides a way to earn monthly from the interest based on your savings amount.
				</p>
			</ActionBar>
			<ToolBarWithoutDrawer />
			<BankInfo>
				<div css={bankLogoContainer}>
					<img
						css={bankLogoCSS}
						src={require(`../assets/product/bank-logos/${bank.bank_code}.svg`)}
						alt="Bank Logo"
					/>
				</div>
				<div css={bankDescriptionCSS}>
					<p>{bank.bank_description}</p>
				</div>
			</BankInfo>
			<form css={formCSS} onSubmit={onSubmitForm}>
				<Label htmlFor="income">Expected income :</Label>
				<TextField
					value={income}
					onChange={handleChange}
					name="income"
					id="income"
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">{'\u20B9'}</InputAdornment>
						),
					}}
					css={css`
						.MuiInputBase-input,
						.MuiTypography-body1 {
							font-size: 1.5rem;
							@media (min-width: 65rem) {
								font-size: 2.375rem;
							}
						}
					`}
				/>
				<FormHelperText
					id="income"
					css={css`
						@media (min-width: 65rem) {
							margin-top: 2.5vh;
						}
					`}
				>
					(in multiples of {'\u20B9'}1)
				</FormHelperText>
				<FormHelperText
					id="income"
					css={css`
						margin-top: 1.25rem;
						@media (min-width: 65rem) {
							margin-top: 2.5vh;
						}
					`}
				>
					Your expected income should be minimum 1000
				</FormHelperText>
				<Label
					htmlFor="deposit"
					css={css`
						@media (min-width: 65rem) {
							margin-top: 8vh;
						}
						margin-top: 2rem;
					`}
				>
					Money to deposit :
				</Label>
				<TextField
					value={deposit}
					onChange={handleChange}
					name="deposit"
					id="deposit"
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">{'\u20B9'}</InputAdornment>
						),
					}}
					css={css`
						.MuiInputBase-input,
						.MuiTypography-body1 {
							font-size: 1.125rem;
							@media (min-width: 65rem) {
								font-size: 1.75rem;
							}
						}
					`}
				/>
				<FormHelperText
					id="deposit"
					css={css`
						@media (min-width: 65rem) {
							display: inline-flex;
							margin-left: -11rem;
							margin-top: 9vh;
						}
					`}
				>
					Minimum investment should be {'\u20B9'}1000
				</FormHelperText>
				<div
					css={css`
						@media (min-width: 65rem) {
							display: flex;
							margin-top: -6.5vh;
						}
					`}
				>
					<Selectors>
						<div className="selector">
							<Label htmlFor="numberOfYears">Years &nbsp;</Label>
							<Select
								variant="outlined"
								native
								value={numberOfYears}
								onChange={handleChange}
								inputProps={{
									name: 'numberOfYears',
									id: 'numberOfYears',
								}}
							>
								{bank.number_of_years.map((value, index) => (
									<option
										data={bank.rate_of_interest[index]}
										key={index}
										value={value}
									>
										{value} yrs
									</option>
								))}
							</Select>
						</div>
						<div className="selector">
							<Label htmlFor="interest">Interest &nbsp;</Label>
							<Select
								variant="outlined"
								native
								value={interest}
								onChange={handleChange}
								inputProps={{
									name: 'interest',
									id: 'interest',
								}}
							>
								{bank.rate_of_interest.map((value, index) => (
									<option
										data={bank.number_of_years[index]}
										key={index}
										value={value}
									>
										{value} %
									</option>
								))}
							</Select>
						</div>
					</Selectors>
					<button
						type="button"
						css={css`
							font-size: 0.8125rem;
							@media (min-width: 65rem) {
								font-size: 1rem;
							}
						`}
						onClick={() => setShowExpectedReturns(!showExpectedReturns)}
					>
						Expected Returns
					</button>
				</div>
				{showExpectedReturns && <ReturnsTable />}
				<div
					css={css`
						margin-top: 2rem;
						display: grid;
						a {
							text-decoration: none;
							font-size: 0.8125rem;
							font-family: Nunito;
							justify-self: center;
							color: #a2a1a1;
							span {
								color: black;
							}
						}
						@media (min-width: 65rem) {
							display: flex;
							justify-content: space-between;
							margin-top: 11vh;
							a:last-child {
								margin-top: 0;
							}
						}
					`}
				>
					<a href={bank.tnc_url}>
						{bank.bank_name} <span>Term and conditions</span>
					</a>
					<Link
						to="/faq"
						css={css`
							margin-top: 20px;
						`}
					>
						Click here for <span>Frequently asked questions</span>
					</Link>
				</div>
				<Button disabled={isInvalid}>Transfer Now</Button>
			</form>
		</Container>
	);
};

export default ProductDetailsTemplate;

export const pageQuery = graphql`
	query($path: String!) {
		deposits(fields: {slug: {eq: $path}}) {
			bank {
				bank_code
				bank_name
				bank_description
				tnc_url
				rate_of_interest
				number_of_years
			}
		}
	}
`;
