import styled from '@emotion/styled';
import {css} from '@emotion/core';

export const Container = styled.div`
	background: white;
	width: 100%;
`;
export const ActionBar = styled.div`
	@media (min-width: 65rem) {
		display: none;
	}
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100vw;
	height: 8.445rem;
	background: #80e8fe;
	font-family: Nunito;
	color: white;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	text-align: left;
	z-index: 1;
	box-shadow: 0 0 4px 0 #00000029;
`;
export const mainTextCSS = css`
	@media (min-width: 34.375rem) {
		left: 14vw;
	}
	font-size: 1.25rem;
	font-weight: bold;
	line-height: 1.6875rem;
	position: fixed;
	top: 3.0625rem;
	left: 20.8vw;
	height: 1.6875rem;
`;
export const subTextCSS = css`
	@media (min-width: 34.375rem) {
		left: 14vw;
		width: 100%;
	}
	font-size: 0.8125rem;
	font-weight: normal;
	line-height: 1.125rem;
	position: fixed;
	top: 5.1875rem;
	left: 20.8vw;
	width: 15.5rem;
	height: 2.3125rem;
`;
export const BankInfo = styled.div`
	@media (min-width: 65rem) {
		top: 13vh;
		height: 12.5vh;
	}
	position: absolute;
	left: 0px;
	top: 8.445rem;
	width: 100vw;
	height: 7.5rem;
	background: #fafafa;
	font-family: Nunito;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	text-align: left;
	color: rgba(0, 0, 0, 0.54);
	font-weight: normal;
	z-index: -1;
`;
export const bankDescriptionCSS = css`
	@media (min-width: 65rem) {
		margin-left: 10vw;
	}
	@media (max-width: 64.99rem) and (min-width: 34.375rem) {
		margin-left: 19vw;
	}
	font-size: 0.8125rem;
	line-height: 1.125rem;
	margin-left: 27.52vw;
	margin-right: 4vw;
	position: relative;
	top: 50%;
	transform: translateY(-50%);
`;
export const backButtonCSS = css`
	position: fixed;
	left: 4.8vw;
	top: 2.8125rem;
	width: 2.125rem;
	height: 2.125rem;
	object-fit: contain;
`;
export const bankLogoContainer = css`
	@media (min-width: 65rem) {
		left: 3.75vw;
	}
	position: absolute;
	left: 7.3866vw;
	top: 50%;
	transform: translateY(-50%);
`;
export const bankLogoCSS = css`
	width: 3.125rem;
	height: 3.125rem;
`;
export const formCSS = css`
	@media (min-width: 65rem) {
		margin-top: 32vh;
		width: 92.5vw;
	}
	.MuiFormHelperText-root {
		font-family: Nunito;
		font-size: 0.8125rem;
		color: #c2c2c2;
		@media (min-width: 65rem) {
			font-size: 1rem;
		}
	}
	.MuiInput-underline {
		font-family: Nunito;
		width: 8rem;
		font-weight: 300;
		margin-top: 0.5rem;
		@media (min-width: 65rem) {
			width: 11rem;
		}
	}
	.MuiOutlinedInput-input {
		padding: 3px 27px 6px 6px;
		font-size: 0.8125rem;
		text-align-last: center;
	}
	.MuiTypography-body1 {
		font-family: Nunito;
		color: black;
		font-weight: 300;
	}
	button {
		font-family: Nunito;
		font-stretch: normal;
		font-style: normal;
		outline: none;
	}
	display: block;
	margin: auto;
	margin-top: 18rem;
	width: 91.47vw;
	z-index: -1;
`;
export const returnsTableCSS = css`
	table {
		@media (min-width: 65rem) {
			width: 76%;
			margin: auto;
			margin-top: 3vh;
			th {
				font-size: 1.5rem;
			}
			td {
				font-size: 1rem;
			}
		}
		border-collapse: collapse;
		margin-top: 21px;
		font-family: Nunito;
		font-size: 0.8125rem;
		width: 100%;
		transition: width 2s ease-out;
		border: solid 0.5px #bcbcbc;
		box-shadow: 0 0 4px 0 #00000029;
		tbody tr:last-child td {
			padding-bottom: 2rem;
		}
		tbody tr:first-of-type td {
			padding-top: 2rem;
		}
	}
	th,
	td {
		text-align: center;
		margin: 0 auto;
		border-right: solid 0.5px #bcbcbc;
	}
	td {
		padding: 8px 0;
		color: #c2c2c2;
	}
	th {
		padding: 28px 0 21px;
		background-color: #80e8fe;
		color: #ffffff;
		font-weight: bold;
		font-size: 0.9375rem;
		width: 30%;
	}
`;
export const Button = styled.button`
	border-radius: 0.3125rem;
	box-shadow: 0 3px 4px 0 #00000029;
	background-color: ${(props) => (props.disabled ? '#e0e0e0e0' : '#80e8fe')};
	color: ${(props) => (props.disabled ? '#a2a1a1' : 'white')};
	width: 19.5rem;
	font-size: 1.125rem;
	display: block;
	height: 2.875rem;
	margin: 1.5625rem auto 2.1875rem;
	@media (min-width: 65rem) {
		margin: -3rem auto 5rem;
	}
	@media (max-width: 20rem) {
		width: 85%;
	}
`;
export const Selectors = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 2rem auto;
	.selector {
		@media (min-width: 65rem) {
			margin-left: 12vw;
			position: relative;
			left: 6vw;
		}
		display: flex;
		align-items: baseline;
	}
	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
		border-color: rgba(0, 0, 0, 0.23);
	}
	@media (min-width: 65rem) {
		display: inline-flex;
		margin: 4vh auto;
	}
`;

export const Label = styled.label`
	font-family: Nunito;
	font-size: 0.8125rem;
	color: #c2c2c2;
	display: block;
	@media (min-width: 65rem) {
		font-size: 1.125rem;
	}
`;
